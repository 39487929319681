import React from 'react'
import DataTable, { TableColumn } from 'react-data-table-component'
import { BsPlusLg } from 'react-icons/bs'

import { CircularProgress } from '@chakra-ui/react'

import { IIndicador } from 'sigap/types/projects'
import { EndButtonRow } from 'sigap/utils/generic/EndButtonRow/styles'
import { GenericButton } from 'sigap/utils/generic/GenericButton/GenericButton'
import { NoDataComponent } from 'sigap/utils/generic/NoDataComponent/NoDataComponent'

import { IndicadoresTarefasContainer } from './styles'

const columns: TableColumn<IIndicador>[] = [
  {
    name: 'Indicador',
    selector: (row) => row.nome,
    sortable: true
  },
  {
    name: 'Atual',
    selector: (row) => row.atual,
    sortable: true
  },
  {
    name: 'Base',
    selector: (row) => row.linha_base,
    sortable: true
  },
  {
    name: 'Meta',
    selector: (row) => row.meta,
    sortable: true
  },
  {
    name: 'Periodicidade',
    selector: (row) => row.periodicidade,
    sortable: true
  }
]

interface IProps {
  tempIndicadores?: IIndicador[]
  indicadores: IIndicador[]
  setShowNovoIndicadorModal: React.Dispatch<React.SetStateAction<boolean>>
  setIndicadorModalData: React.Dispatch<React.SetStateAction<IIndicador>>
  isPending?: boolean
  indicadoresLoading: boolean
}

export const IndicadoresList: React.FC<IProps> = ({
  tempIndicadores,
  indicadores,
  setShowNovoIndicadorModal,
  setIndicadorModalData,
  isPending,
  indicadoresLoading
}) => {
  const handleShowNovoIndicadorModal = (): void => {
    setShowNovoIndicadorModal(true)
  }

  const handleOnRowClick = (indicador: IIndicador): void => {
    handleShowNovoIndicadorModal()
    setIndicadorModalData(indicador)
  }

  return (
    <IndicadoresTarefasContainer>
      <div className="meta-header">INDICADORES</div>
      <div className="meta-content">
        <div className="tarefa-list">
          <div className="tarefa-list-body">
            <DataTable
              columns={columns}
              data={tempIndicadores.length > 0 ? tempIndicadores : indicadores}
              onRowClicked={(indicador) => (!isPending ? handleOnRowClick(indicador) : undefined)}
              dense
              pointerOnHover={!isPending}
              highlightOnHover={!isPending}
              striped
              progressPending={indicadoresLoading}
              progressComponent={<CircularProgress isIndeterminate color="#001B37" />}
              noDataComponent={<NoDataComponent dataType="indicadores" />}
            />
          </div>
          <EndButtonRow style={{ padding: '16px' }}>
            <GenericButton
              className="nova-tarefa-button"
              colorScheme="green"
              onClick={handleShowNovoIndicadorModal}
              isDisabled={isPending}
              rightIcon={<BsPlusLg />}
            >
              Adicionar Indicador
            </GenericButton>
          </EndButtonRow>
        </div>
      </div>
    </IndicadoresTarefasContainer>
  )
}
