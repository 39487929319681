export const DEFAULT_SUCCESS_TOAST_MESSAGE = {
  success: {
    title: 'Sucesso!'
  },
  error: {
    title: 'Erro',
    description: 'Um erro foi detectado.'
  },
  loading: { title: 'Carregando', description: 'Aguarde...' }
}

export const LOGIN_TOAST_MESSAGE = {
  success: {
    title: 'Entrando...'
  },
  error: {
    title: 'Erro',
    description: 'Erro ao realizar login.'
  },
  loading: { title: 'Carregando login', description: 'Aguarde...' }
}

export const FORGOT_PASSWORD_TOAST_MESSAGE = {
  success: {
    title: 'E-mail de redefinição de senha enviado'
  },
  error: {
    title: 'Erro',
    description: 'Erro ao requerer redefinição de senha.'
  },
  loading: { title: 'Carregando', description: 'Aguarde...' }
}

export const USER_CREATION_TOAST_MESSAGE = {
  success: {
    title: 'Usuário criado!'
  },
  error: {
    title: 'Erro',
    description: 'Erro ao requerer criação de usuário.'
  },
  loading: { title: 'Carregando', description: 'Aguarde...' }
}

export const CONTRATO_EDIT_TOAST_MESSAGE = {
  success: {
    title: 'Contrato editado',
    description: 'Seu contrato foi editado com sucesso.'
  },
  error: {
    title: 'Erro',
    description: 'Erro ao editar contrato.'
  },
  loading: { title: 'Carregando', description: 'Aguarde...' }
}

export const PROJETO_EDIT_TOAST_MESSAGE = {
  success: {
    title: 'Projeto editado',
    description: 'Seu projeto foi editado com sucesso.'
  },
  error: {
    title: 'Erro',
    description: 'Erro ao editar projeto.'
  },
  loading: { title: 'Carregando', description: 'Aguarde...' }
}

export const DEMANDA_EDIT_TOAST_MESSAGE = {
  success: {
    title: 'Demanda editada',
    description: 'Sua demanda foi editada com sucesso.'
  },
  error: {
    title: 'Erro',
    description: 'Erro ao editar demanda.'
  },
  loading: { title: 'Carregando', description: 'Aguarde...' }
}
export const PRODUTO_EDIT_TOAST_MESSAGE = {
  success: {
    title: 'Produto salvo',
    description: 'Seu produto foi salvo com sucesso.'
  },
  error: {
    title: 'Erro',
    description: 'Erro ao editar produto.'
  },
  loading: { title: 'Carregando', description: 'Aguarde...' }
}
export const TAREFA_EDIT_TOAST_MESSAGE = {
  success: {
    title: 'Tarefa salva',
    description: 'Sua tarefa foi salva com sucesso.'
  },
  error: {
    title: 'Erro',
    description: 'Erro ao editar tarefa.'
  },
  loading: { title: 'Carregando', description: 'Aguarde...' }
}
export const INDICADOR_EDIT_TOAST_MESSAGE = {
  success: {
    title: 'Indicador salvo',
    description: 'Seu indicador foi salvo com sucesso.'
  },
  error: {
    title: 'Erro ao editar indicador',
    description: 'Houve um erro ao tentar editar o indicador.'
  },
  loading: { title: 'Carregando', description: 'Aguarde...' }
}

export const CONTRATO_CREATE_TOAST_MESSAGE = {
  success: {
    title: 'Contrato criado',
    description: 'Seu contrato foi criado com sucesso.'
  },
  error: {
    title: 'Erro',
    description: 'Erro ao criar contrato.'
  },
  loading: { title: 'Carregando', description: 'Aguarde...' }
}
export const PROJETO_APPROVAL_TOAST_MESSAGE = {
  success: {
    title: 'Projeto enviado para aprovação',
    description: 'Seu projeto foi criado e enviado para aprovação.'
  },
  error: {
    title: 'Erro',
    description: 'Erro ao criar projeto.'
  },
  loading: { title: 'Carregando', description: 'Aguarde...' }
}
export const PRODUTO_CREATE_TOAST_MESSAGE = {
  success: {
    title: 'Produto criado',
    description: 'Seu produto foi criado com sucesso.'
  },
  error: {
    title: 'Erro',
    description: 'Erro ao criar produto.'
  },
  loading: { title: 'Carregando', description: 'Aguarde...' }
}
export const DEMANDA_CREATE_TOAST_MESSAGE = {
  success: {
    title: 'Demanda criada',
    description: 'Sua demanda foi criada com sucesso.'
  },
  error: {
    title: 'Erro',
    description: 'Erro ao criar demanda.'
  },
  loading: { title: 'Carregando', description: 'Aguarde...' }
}
export const TAREFA_CREATE_TOAST_MESSAGE = {
  success: {
    title: 'Tarefa criada',
    description: 'Sua tarefa foi criada com sucesso.'
  },
  error: {
    title: 'Erro',
    description: 'Erro ao criar tarefa.'
  },
  loading: { title: 'Carregando', description: 'Aguarde...' }
}
export const INDICADOR_CREATE_TOAST_MESSAGE = {
  success: {
    title: 'Indicador criado',
    description: 'Seu indicador foi criado com sucesso.'
  },
  error: {
    title: 'Erro ao criar indicador',
    description: 'Houve um erro ao tentar criar o indicador.'
  },
  loading: { title: 'Carregando', description: 'Aguarde...' }
}
