import React, { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { ModalOverlay, ModalContent, ModalHeader, ModalBody, Input, Textarea, ModalFooter } from '@chakra-ui/react'

import { GenericButton } from 'sigap/utils/generic/GenericButton/GenericButton'
import { GenericSelect } from 'sigap/utils/generic/GenericSelect/GenericSelect'
import { ModalContainer } from 'sigap/utils/generic/ModalContainer/ModalContainer'
import { SingleDatePicker } from 'sigap/utils/generic/SingleDatePicker/SingleDatePicker'

interface IFormData {
  nome: string
  email: string
  perfil: Option<string>
  subsecretaria: Option<string>
  area: Option<string>
}

interface IProps {
  userModalData: IUser
  showUserModal: boolean
  setUserModalData: React.Dispatch<React.SetStateAction<IUser>>
  setShowUserModal: React.Dispatch<React.SetStateAction<boolean>>
}

const mockPerfis = [
  { label: 'Administrador', value: 'admin' },
  { label: 'Usuário', value: 'user' },
  { label: 'Gestor', value: 'gestor' }
]

const mockAreas = [
  { label: 'Assessoria Jurídica', value: 2 },
  { label: 'Chefia de Gabinete ', value: 3 },
  { label: 'Superintendência de Gestão de Pessoas,Coordenadoria de Recursos Humanos', value: 4 },
  { label: 'Assessoria de Recursos Humanos', value: 6 },
  { label: 'Superintendência de Retenção e Atração de Investimentos', value: 7 },
  { label: 'Coordenadoria da Secretaria Executiva da CPPDE', value: 8 },
  { label: 'Superintendência Administração das Vinculadas ', value: 9 },
  { label: 'Superintendência do Fundo Soberano', value: 10 }
]

const mockSubSec = [
  { label: 'Subsecretaria de Indústria, Comércio e Serviços', value: 1 },
  { label: '⁠Subsecretaria Adjunta de Projetos Estratégicos', value: 2 },
  { label: 'Subsecretaria Adjunta de Comunicação', value: 3 },
  { label: '⁠Subsecretaria Executiva', value: 4 },
  { label: '⁠Gabinete do Secretário', value: 5 }
]

export const UserModal: React.FC<IProps> = ({ userModalData, showUserModal, setUserModalData, setShowUserModal }) => {
  const { handleSubmit, control, setValue, watch } = useForm<IFormData>()

  const currentFormData = watch()

  const isFormComplete = useMemo(
    () =>
      currentFormData.nome?.length > 0 &&
      currentFormData.email?.length > 0 &&
      currentFormData.perfil != null &&
      currentFormData.subsecretaria != null &&
      currentFormData.area != null,
    [currentFormData]
  )

  const handleClose = () => {
    setShowUserModal(false)
    setUserModalData(null)
  }

  const handleClickSubmitEdit = () => {}

  const handleClickSubmitCreate = () => {}

  useEffect(() => {
    if (userModalData) {
      setValue('nome', userModalData.nome)
      setValue('email', userModalData.email)
      setValue('perfil', { label: userModalData.perfil, value: userModalData.perfil })
      setValue('subsecretaria', { label: userModalData.subsecretaria, value: userModalData.subsecretaria })
      setValue('area', { label: userModalData.area, value: userModalData.area })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userModalData])

  return (
    <ModalContainer isOpen={showUserModal} onClose={console.log}>
      <ModalOverlay />
      <ModalContent minWidth={800} className="nova-meta-modal-content">
        <ModalHeader
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          className="nova-meta-modal-header"
          color={'gray.900'}
          fontFamily={'Poppins'}
          fontSize={32}
          fontStyle={'normal'}
          fontWeight={700}
          lineHeight={'36px'}
        >
          Usuário
        </ModalHeader>
        <ModalBody
          // display={'grid'}
          padding={'0px 48px'}
          // gap={'32px'}
          className="nova-meta-modal-body"
          // gridTemplateColumns={'1fr 1fr'}
        >
          <div className="label-input-bundle">
            <label>Nome</label>
            <Controller name="nome" control={control} render={({ field }) => <Input variant="flushed" {...field} />} />
          </div>
          <div className="label-input-bundle">
            <label>Email</label>
            <Controller name="email" control={control} render={({ field }) => <Input variant="flushed" {...field} />} />
          </div>
          <div className="label-input-bundle">
            <label>Perfil</label>
            <Controller
              name="perfil"
              control={control}
              render={({ field }) => (
                <GenericSelect
                  options={mockPerfis?.map((perfil) => ({ label: perfil.label, value: perfil.value }))}
                  {...field}
                />
              )}
            />
          </div>
          <div className="label-input-bundle">
            <label>Sub Sercretaria</label>
            <Controller
              name="subsecretaria"
              control={control}
              render={({ field }) => (
                <GenericSelect
                  options={mockSubSec?.map((subsecretaria) => ({
                    label: subsecretaria.label,
                    value: subsecretaria.value
                  }))}
                  {...field}
                />
              )}
            />
          </div>
          <div className="label-input-bundle">
            <label>Área</label>
            <Controller
              name="area"
              control={control}
              render={({ field }) => (
                <GenericSelect
                  options={mockAreas?.map((area) => ({ label: area.label, value: area.value }))}
                  {...field}
                />
              )}
            />
          </div>
        </ModalBody>
        <ModalFooter display={'flex'} gap={'80px'}>
          {userModalData ? (
            <GenericButton onClick={handleSubmit(handleClickSubmitEdit)} isDisabled={!isFormComplete}>
              Salvar Edição
            </GenericButton>
          ) : (
            <GenericButton onClick={handleSubmit(handleClickSubmitCreate)} isDisabled={!isFormComplete}>
              Adicionar e Salvar
            </GenericButton>
          )}

          <GenericButton variant={'outline'} onClick={handleClose} color={'#242731'}>
            Cancelar
          </GenericButton>
        </ModalFooter>
      </ModalContent>
    </ModalContainer>
  )
}
