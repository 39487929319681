import { TableColumn } from 'react-data-table-component'

export const documentoColumns: TableColumn<IDocumentos>[] = [
  {
    name: 'Nome',
    selector: (row) => row.nome,
    sortable: true
  },
  {
    name: 'Link',
    selector: (row) => row.link,
    sortable: true
  }
]
