import styled from 'styled-components'

export const FullyApprovedProjecteContainer = styled.div`
  padding: 0 41px 0px 21px;
  .approved-project-header {
    color: #242731;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    padding-left: 85px;
    padding-top: 26px;
    display: flex;
    justify-content: space-between;
  }

  .approved-project-body {
    padding-top: 55px;
    display: flex;
    flex-direction: column;
    gap: 80px;

    .body-basic-data {
      display: grid;
      grid-template-columns: 0.75fr 0.25fr;
      gap: 75px;
      position: relative;
      z-index: 2;

      .large-card-info-body {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 75px;
      }
    }
  }
`
