import React from 'react'
import DataTable, { TableColumn } from 'react-data-table-component'
import { BsPlusLg } from 'react-icons/bs'

import { CircularProgress } from '@chakra-ui/react'
import * as dateFns from 'date-fns'

import { IndicadoresTarefasContainer } from 'sigap/components/View/Projects/ProjectEditor/ApprovedProjectToComplete/IndicadoresList/styles'
import { IProduto } from 'sigap/types/contracts'
import { DateFormat } from 'sigap/utils/constants/enums'
import { EndButtonRow } from 'sigap/utils/generic/EndButtonRow/styles'
import { GenericButton } from 'sigap/utils/generic/GenericButton/GenericButton'
import { NoDataComponent } from 'sigap/utils/generic/NoDataComponent/NoDataComponent'
import { addThreeDigits } from 'sigap/utils/methods/addThreeDigits'

const columns: TableColumn<IProduto>[] = [
  {
    name: 'Nome',
    selector: (row) => row.nome,
    sortable: true
  },
  {
    name: 'Responsável',
    selector: (row) => (row.responsavel ? (row.responsavel as IBasicInfo).nome : '-'),
    sortable: true
  },

  {
    name: 'Valor',
    selector: (row) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.valor),
    sortable: true
  },
  {
    name: 'Entrega',
    selector: (row) => dateFns.format(addThreeDigits(row.prazo_entrega), DateFormat.BR_DATE),
    sortable: true
  }
]

interface IProps {
  setShowNovoProdutoModal: React.Dispatch<React.SetStateAction<boolean>>
  tempProdutos?: IProduto[]
  produtos: IProduto[]
  setProdutoModalData: React.Dispatch<React.SetStateAction<IProduto>>
  isPending?: boolean
  produtosLoading: boolean
}

export const ProdutoList: React.FC<IProps> = ({
  setShowNovoProdutoModal,
  tempProdutos,
  produtos,
  setProdutoModalData,
  isPending,
  produtosLoading
}) => {
  const handleShowNovoProdutoModal = (): void => {
    setShowNovoProdutoModal(true)
  }

  const handleOnRowClick = (produto: IProduto): void => {
    handleShowNovoProdutoModal()
    setProdutoModalData(produto)
  }

  return (
    <IndicadoresTarefasContainer>
      <div className="meta-header">PRODUTOS</div>
      <div className="meta-content">
        <div className="tarefa-list">
          <div className="tarefa-list-body">
            <DataTable
              columns={columns}
              data={tempProdutos?.length > 0 ? tempProdutos : produtos}
              onRowClicked={(produto) => (!isPending ? handleOnRowClick(produto) : undefined)}
              dense
              pointerOnHover={!isPending}
              highlightOnHover={!isPending}
              striped
              progressPending={produtosLoading}
              progressComponent={<CircularProgress isIndeterminate color="#001B37" />}
              noDataComponent={<NoDataComponent dataType="produtos" />}
            />
          </div>
          <EndButtonRow style={{ padding: '16px' }}>
            <GenericButton
              onClick={handleShowNovoProdutoModal}
              isDisabled={isPending}
              rightIcon={<BsPlusLg />}
              colorScheme="green"
            >
              Adicionar Produto
            </GenericButton>
          </EndButtonRow>
        </div>
      </div>
    </IndicadoresTarefasContainer>
  )
}
