import React, { useContext, useEffect, useState } from 'react'

import { useRequest } from 'ahooks'

import { AuthenticationContext } from 'sigap/context/AuthenticationContext'
import { auth } from 'sigap/services/auth'
import { getStatusTarefasChartData } from 'sigap/services/charts'

import { ILineSeries, MonthLineChart } from './LineChart/MonthLineChart'
import { IPieSeries, PieChart } from './PieChart/PieChart'
import { DashboardContainer } from './styles'

const mockEntitiesCreatedChartData: ILineSeries[] = [
  {
    data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    name: 'Novos Projetos'
  },
  {
    data: [11, 17, 14, 19, 10, 12, 20, 15, 13, 16, 18, 10],
    name: 'Novos Contratos'
  },
  {
    data: [8, 14, 5, 10, 11, 13, 7, 12, 6, 15, 9, 8],
    name: 'Novas Demandas'
  }
]

interface IEntititesChartData {
  projeto: IEntititesChartData[]
  contrato: IEntititesChartData[]
  demanda: IEntititesChartData[]
}

interface IEntititesChartDataObject {
  Year: string
  Month: string
  count: number
}

interface IHasCreatedAt {
  created_at: string
}

export const Dashboard: React.FC = () => {
  const [normalizedTarefaChartData, setNormalizedTarefaChartData] = useState<IPieSeries[]>()

  const { data: statusTarefaChartData, loading: statusTarefaChartLoading } = useRequest(getStatusTarefasChartData)

  const authContext = useContext(AuthenticationContext)
  const statusNames = authContext?.currentUser?.status_tarefas

  const normalizeTarefaChartData = (): void => {
    if (statusNames) {
      const normalizedData: IPieSeries[] = Object.entries(statusNames).map((statusName) => {
        const refName = statusName[0]

        return {
          name: statusName[1],
          y: statusTarefaChartData?.[refName]
        }
      })
      setNormalizedTarefaChartData(normalizedData)
    }
  }

  function groupByMonth<T extends IHasCreatedAt>(data: T[]): Record<string, T[]> {
    return data?.reduce((acc, obj) => {
      const date = new Date(obj.created_at)
      const yearMonth = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`

      if (!acc[yearMonth]) {
        acc[yearMonth] = []
      }

      acc[yearMonth].push(obj)

      return acc
    }, {} as Record<string, T[]>)
  }

  const normalizeEntitiesChartData = (): void => {
    const mockData: IEntititesChartData = {
      contrato: [],
      demanda: [],
      projeto: []
    }

    const normalizedData: ILineSeries[] = Object.entries(mockData).map((data) => {
      const refName = data[0]
      console.log(data)

      return {
        name: data[1],
        data: statusTarefaChartData?.[refName]
      }
    })
  }

  useEffect(() => {
    normalizeTarefaChartData()
    normalizeEntitiesChartData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext, statusTarefaChartData])

  return (
    <DashboardContainer>
      <MonthLineChart
        series={mockEntitiesCreatedChartData}
        title="Entidades criadas por mês"
        yTitle="Número de entidades criadas"
      />
      <PieChart series={normalizedTarefaChartData} seriesTitle="Tarefas" title="Status de Tarefas" />
    </DashboardContainer>
  )
}
