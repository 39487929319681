import { IContrato } from 'sigap/types/contracts'
import { CONTRATOS, PROJETO, USER, api } from 'sigap/utils/constants'

export async function listContratos(): Promise<IContrato[]> {
  const { data: response } = await api.get(`/${CONTRATOS}`)

  return response
}

export async function listContratosByUser(userId: number): Promise<void> {
  const { data: response } = await api.get(`/${CONTRATOS}/${USER}/${userId}`)

  return response
}

export async function listContratosByProjeto(projetoId: number): Promise<void> {
  const { data: response } = await api.get(`/${CONTRATOS}/${PROJETO}/${projetoId}`)

  return response
}

export async function getContrato(contratoId: number): Promise<IContrato> {
  const { data: response } = await api.get(`/${CONTRATOS}/${contratoId}`)

  return response
}

export async function insertContrato(contrato: IContrato): Promise<{ id_contrato: number }> {
  const { data: response } = await api.post(`/${CONTRATOS}`, contrato)

  return response
}

export async function editContrato(contrato: IContrato, contratoId: number): Promise<void> {
  const { data: response } = await api.patch(`/${CONTRATOS}/${contratoId}`, contrato)

  return response
}

export async function insertCommentOnContrato(comment: IComment, contratoId: number): Promise<void> {
  const { data: response } = await api.post(`/${CONTRATOS}/${contratoId}/comentario`, comment)

  return response
}

export async function getCommentsOnContrato(contratoId: number): Promise<IComment[]> {
  const { data: response } = await api.get(`/${CONTRATOS}/${contratoId}/comentario`)

  return response
}
