import React, { useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Button, FormControl, Input, InputGroup, InputRightElement, useToast } from '@chakra-ui/react'

import { IAuthNewUser } from 'sigap/services/users'
import { USER_CREATION_TOAST_MESSAGE } from 'sigap/utils/constants/requestToastMessages'

interface IFormData {
  nome: string
  email: string
  subsecretaria: string
  cargo: string
  password: string
  passwordVerify: string
}

interface IProps {
  handleGoToDefaultAuth: () => void
  handleNewUser: (newUser: IAuthNewUser) => Promise<void>
}

export const NewUser: React.FC<IProps> = ({ handleGoToDefaultAuth, handleNewUser }) => {
  const [show, setShow] = useState<boolean>(false)
  const { handleSubmit, control, watch } = useForm<IFormData>({
    mode: 'all'
  })
  const handleShowPassword = (): void => setShow(!show)

  const toast = useToast()

  const currentFormData = watch()

  const passwordsMatch = useMemo(() => currentFormData?.password === currentFormData?.passwordVerify, [currentFormData])

  const handleClickSubmit = (formObject: IFormData): void => {
    toast.promise<void, Error>(handleNewUser(formObject), USER_CREATION_TOAST_MESSAGE)
  }

  return (
    <>
      <div className="auth-header">Criar Conta</div>
      <FormControl className="form-control-container">
        <Controller
          name="nome"
          control={control}
          render={({ field }) => <Input variant="flushed" placeholder="Nome Completo" {...field} />}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => <Input variant="flushed" placeholder="E-mail" type="email" {...field} />}
        />
        <Controller
          name="subsecretaria"
          control={control}
          render={({ field }) => <Input variant="flushed" placeholder="Subsecretaria" {...field} />}
        />
        <Controller
          name="cargo"
          control={control}
          render={({ field }) => <Input variant="flushed" placeholder="Cargo" {...field} />}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <InputGroup size="md" {...field}>
              <Input variant="flushed" type={show ? 'text' : 'password'} placeholder="Senha" />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleShowPassword}>
                  {show ? 'Hide' : 'Show'}
                </Button>
              </InputRightElement>
            </InputGroup>
          )}
        />
        <Controller
          name="passwordVerify"
          control={control}
          render={({ field }) => (
            <InputGroup size="md" {...field}>
              <Input variant="flushed" type={show ? 'text' : 'password'} placeholder="Repita sua senha" />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleShowPassword}>
                  {show ? 'Hide' : 'Show'}
                </Button>
              </InputRightElement>
            </InputGroup>
          )}
        />
      </FormControl>
      <Button width={'100%'} colorScheme="green" onClick={handleSubmit(handleClickSubmit)} isDisabled={!passwordsMatch}>
        Criar Conta
      </Button>
      <div className="bottom-link-container">
        <div className="go-back-login">
          Já possui uma conta?<span onClick={handleGoToDefaultAuth}>Login</span>
        </div>
      </div>
    </>
  )
}
