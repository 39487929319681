/* eslint-disable camelcase */
import React, { useState, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import ReactSelectCreatable from 'react-select/creatable'

import { CardHeader, Checkbox, Input, useToast } from '@chakra-ui/react'
import { useRequest } from 'ahooks'

import { IIndicadoresDto, getIndicadoresByProject, insertIndicadores } from 'sigap/services/indicadores'
import { listOds } from 'sigap/services/ods'
import { editProject, insertProject } from 'sigap/services/projects'
import { listPublicoAlvo } from 'sigap/services/publicoAlvo'
import { listSedeics } from 'sigap/services/sedeics'
import { listSubsecretarias } from 'sigap/services/subsecretaria'
import { ITarefasDto, getTarefasByProject, insertTarefasOnProject } from 'sigap/services/tarefas'
import { listUsers } from 'sigap/services/users'
import { IIndicador, IProject } from 'sigap/types/projects'
import { ITarefa } from 'sigap/types/tarefas'
import { documentoColumns } from 'sigap/utils/constants/columns'
import { Pendencias } from 'sigap/utils/constants/enums'
import { PROJETO_APPROVAL_TOAST_MESSAGE, PROJETO_EDIT_TOAST_MESSAGE } from 'sigap/utils/constants/requestToastMessages'
import { GenericTable } from 'sigap/utils/generic'
import { LargeCardInfo } from 'sigap/utils/generic/CardInfo/CardInfo'
import { CommentChat } from 'sigap/utils/generic/CommentChat/CommentChat'
import { DuracaoPicker } from 'sigap/utils/generic/DuracaoPicker/DuracaoPicker'
import { SmallFormCard } from 'sigap/utils/generic/FormCardStyles/FormCardStyles'
import { GenericButton } from 'sigap/utils/generic/GenericButton/GenericButton'
import { GenericSelect } from 'sigap/utils/generic/GenericSelect/GenericSelect'
import { NumberMaskInput } from 'sigap/utils/generic/MaskInput/MaskInput'
import { PendingAlert } from 'sigap/utils/generic/PendingAlert/PendingAlert'
import { addThreeDigits } from 'sigap/utils/methods/addThreeDigits'
import { formatCurrencyToNumber } from 'sigap/utils/methods/formatCurrencyToNumber'
import { generateOdsLabel } from 'sigap/utils/methods/generateOdsLabel'
import { returnNumberArrayFromIBasicInfoArray } from 'sigap/utils/methods/returnNumberArrayFromIBasicInfoArray'
import { returnNumberOptionArrayFromBasicInfoArray } from 'sigap/utils/methods/returnNumberOptionArrayFromBasicInfoArray'
import { returnNumberValueArrayFromMultiSelect } from 'sigap/utils/methods/returnNumberValueArrayFromMultiSelect'
import { returnStringOptionArrayFromStringArray } from 'sigap/utils/methods/returnStringOptionArrayFromStringArray'
import { returnStringValueArrayFromMultiSelect } from 'sigap/utils/methods/returnStringValueArrayFromMultiSelect'

import { IndicadoresList } from '../ApprovedProjectToComplete/IndicadoresList/IndicadoresList'
import { ModalNovaTarefa } from '../ApprovedProjectToComplete/ModalNovaTarefa/ModalNovaTarefa'
import { ModalNovoIndicador } from '../ApprovedProjectToComplete/ModalNovoIndicador/ModalNovoIndicador'
import { TarefaList } from '../ApprovedProjectToComplete/TarefaList/TarefaList'
import { FullyApprovedProjecteContainer } from './styles'

interface IFormData {
  nome: string
  objetivo: string
  subsecretaria: Option<number>[]
  orcamento: string
  justificativa: string
  publico_alvo: Option<number>[]
  responsavel: Option<number>
  gerente: Option<number>
  equipe: Option<number>[]
  ods: Option<number>[]
  parcerias: Option<string>[]
  sedeics: Option<number>[]
  ppa: boolean
}

interface IProps {
  project: IProject
  refreshProject: () => Promise<IProject>
}

export const FullyApprovedProject: React.FC<IProps> = ({ project, refreshProject }) => {
  const [showNovaTarefaModal, setShowNovaTarefaModal] = useState<boolean>()
  const [showNovoIndicadorModal, setShowNovoIndicadorModal] = useState<boolean>()
  const [tarefaModalData, setTarefaModalData] = useState<ITarefa>()
  const [indicadorModalData, setIndicadorModalData] = useState<IIndicador>()
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [selectedDates, setSelectedDates] = useState<Date[]>([new Date(), new Date()])
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)
  const [tempTarefas, setTempTarefas] = useState<ITarefa[]>([])
  const [tempIndicadores, setTempIndicadores] = useState<IIndicador[]>([])
  const [showNovoDocumentoModal, setShowNovoDocumentoModal] = useState<boolean>(false)
  const [documentoModalData, setDocumentoModalData] = useState<IDocumentos>()

  const { handleSubmit, control, setValue, watch } = useForm<IFormData>()
  const { data: sedeics } = useRequest(listSedeics)

  const { data: users, loading: usersLoading, error: usersError } = useRequest(listUsers)
  const { data: publicoAlvoList } = useRequest(listPublicoAlvo)
  const {
    data: subsecretarias,
    loading: subsecretariaLoading,
    error: subsecretariaError
  } = useRequest(listSubsecretarias)

  const { data: ods, loading: odsLoading, error: odsError } = useRequest(listOds)

  const navigate = useNavigate()

  const params = useParams()
  const {
    data: indicadores,
    refreshAsync: refreshIndicadores,
    loading: indicadoresLoading
  } = useRequest(getIndicadoresByProject, {
    defaultParams: [Number(params.id)],
    ready: !!params.id
  })
  const {
    data: tarefas,
    refreshAsync: refreshTarefas,
    loading: tarefasLoading
  } = useRequest(getTarefasByProject, {
    defaultParams: [Number(params.id)],
    ready: !!params.id
  })

  const currentFormObject = watch()
  const toast = useToast()

  const isPending = useMemo(
    () => project?.pendencia?.toLowerCase() === Pendencias.PENDENTE_FASE_2.toLowerCase(),
    [project]
  )

  const isFormComplete = useMemo(
    () =>
      currentFormObject.sedeics != null &&
      currentFormObject.ods != null &&
      currentFormObject.responsavel != null &&
      currentFormObject.orcamento?.length > 0 &&
      currentFormObject.parcerias?.length > 0 &&
      currentFormObject.equipe != null &&
      currentFormObject.publico_alvo != null &&
      currentFormObject.subsecretaria != null &&
      currentFormObject.justificativa.length > 0 &&
      currentFormObject.nome.length > 0 &&
      currentFormObject.objetivo.length > 0 &&
      currentFormObject.gerente != null &&
      tempTarefas.length > 0 &&
      tempIndicadores.length > 0,
    [currentFormObject, tempIndicadores, tempTarefas]
  )

  const handleSetIsEditMode = (): void => {
    setIsEditMode(true)
  }

  const returnNumberOptionFromBasicInfo = (basicInfo: IBasicInfo): Option<number> => {
    return { label: basicInfo.nome, value: basicInfo.id }
  }

  const handleDateChange = (dates: Date[]): void => {
    setSelectedDates(dates)
  }

  const handleCancelEdit = (): void => {
    refreshProject()
    setIsEditMode(false)
  }

  const handleShowNovoDocumentoModal = (): void => {
    setShowNovoDocumentoModal(true)
  }

  const normalizeProjectData = (formObject: IFormData): IProject => {
    const odsIdArray = returnNumberValueArrayFromMultiSelect(formObject.ods)
    const equipeIdArray = returnNumberValueArrayFromMultiSelect(formObject.equipe)

    const parceriasArray = returnStringValueArrayFromMultiSelect(formObject.parcerias)
    const sedeicsIdArray = returnNumberValueArrayFromMultiSelect(formObject.sedeics)
    const publicoAlvoIdArray = returnNumberValueArrayFromMultiSelect(formObject.publico_alvo)
    const subsecretariaIdArray = returnNumberValueArrayFromMultiSelect(formObject.subsecretaria)
    const normalizedObject: IProject = {
      equipe: equipeIdArray,
      gerente: formObject.gerente.label as string,
      justificativa: formObject.justificativa,
      nome: formObject.nome,
      objetivo: formObject.objetivo,
      publico_alvo: publicoAlvoIdArray,
      subsecretaria: subsecretariaIdArray,
      sedeics: sedeicsIdArray,
      ods: odsIdArray,
      orcamento: formatCurrencyToNumber(formObject.orcamento),
      parcerias: parceriasArray,
      ppa: formObject.ppa,
      responsavel: [formObject.responsavel?.value],
      duracao: {
        inicio: selectedDates[0].getTime(),
        fim: selectedDates[1].getTime()
      },
      id: project?.id
    }

    return normalizedObject
  }

  const handleSaveEditProject = async (formObject: IFormData): Promise<void> => {
    try {
      setButtonLoading(true)
      const normalizedProjectObject = normalizeProjectData(formObject)
      setIsEditMode(false)
      await editProject(normalizedProjectObject)
      refreshProject()
    } finally {
      setButtonLoading(false)
    }
  }

  const handleClickSubmitEdit = (formObject: IFormData): void => {
    toast.promise<void, Error>(handleSaveEditProject(formObject), PROJETO_EDIT_TOAST_MESSAGE)
  }

  const normalizeIndicadoresDataForCreate = (): IIndicadoresDto => {
    if (tempIndicadores) {
      const indicadoresDto: IIndicadoresDto = {
        indicadores: tempIndicadores
      }
      console.log('normalizeIndicadoresDataForCreate')

      return indicadoresDto
    }
  }

  const normalizeTarefasDataforCreate = (): ITarefasDto => {
    if (tempTarefas) {
      const newTarefas = tempTarefas.map((tarefa) => ({
        ...tarefa,
        responsavel: returnNumberArrayFromIBasicInfoArray(tarefa.responsavel as IBasicInfo[])
      }))
      const tarefasDto: ITarefasDto = {
        tarefas: newTarefas
      }
      console.log('normalizeTarefasDataforCreate')

      return tarefasDto
    }
  }

  const handleRequestApproval = async (formObject: IFormData): Promise<void> => {
    try {
      setButtonLoading(true)
      const normalizedProjectObject = normalizeProjectData(formObject)
      const normalizedIndicadoresObject = normalizeIndicadoresDataForCreate()
      const normalizedTarefasObject = normalizeTarefasDataforCreate()
      const response = await insertProject(normalizedProjectObject)
      await insertIndicadores(normalizedIndicadoresObject, response.id_projeto)
      await insertTarefasOnProject(normalizedTarefasObject, response.id_projeto)

      navigate('/projetos/')
    } catch (e) {
      console.log(e)
      throw e
    } finally {
      setButtonLoading(false)
    }
  }

  const handleClickSubmitApproval = (formObject: IFormData): void => {
    toast.promise<void, Error>(handleRequestApproval(formObject), PROJETO_APPROVAL_TOAST_MESSAGE)
  }

  useEffect(() => {
    if (project) {
      setIsEditMode(false)

      setValue('nome', project.nome)
      setValue('objetivo', project.objetivo)
      setValue(
        'orcamento',
        project.orcamento?.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        })
      )
      setValue('justificativa', project.justificativa)
      setValue('subsecretaria', returnNumberOptionArrayFromBasicInfoArray(project.subsecretaria as IBasicInfo[]))
      setValue('publico_alvo', returnNumberOptionArrayFromBasicInfoArray(project.publico_alvo as IBasicInfo[]))
      setValue('responsavel', returnNumberOptionArrayFromBasicInfoArray(project.responsavel as IBasicInfo[])[0])
      setValue(
        'gerente',
        project.gerente
          ? returnNumberOptionFromBasicInfo(project.gerente as IBasicInfo)
          : { label: undefined, value: undefined }
      )
      setValue('equipe', returnNumberOptionArrayFromBasicInfoArray(project.equipe as IBasicInfo[]))
      setValue('sedeics', returnNumberOptionArrayFromBasicInfoArray(project.sedeics as IBasicInfo[]))
      setValue('ods', returnNumberOptionArrayFromBasicInfoArray(project.ods as IBasicInfo[]))
      setValue('ppa', project.ppa)
      setValue('parcerias', returnStringOptionArrayFromStringArray(project.parcerias))
      setSelectedDates([
        new Date(addThreeDigits(project.duracao?.inicio)),
        new Date(addThreeDigits(project.duracao?.fim))
      ])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project])

  useEffect(() => {
    if (!project) {
      setIsEditMode(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project])

  return (
    <>
      <FullyApprovedProjecteContainer>
        {isPending && <PendingAlert />}
        <div className="approved-project-header">
          {project?.nome ?? 'Novo projeto'}
          {project ? (
            <>
              {isEditMode ? (
                <div className="edit-button-row">
                  <GenericButton
                    colorScheme="green"
                    onClick={handleSubmit(handleClickSubmitEdit)}
                    isLoading={buttonLoading}
                  >
                    Salvar
                  </GenericButton>
                  <GenericButton variant={'outline'} color={'#242731'} onClick={handleCancelEdit}>
                    Cancelar
                  </GenericButton>
                </div>
              ) : (
                <>
                  <GenericButton onClick={handleSetIsEditMode} isDisabled={isPending}>
                    Editar
                  </GenericButton>
                </>
              )}
            </>
          ) : (
            <GenericButton
              colorScheme="orange"
              onClick={handleSubmit(handleClickSubmitApproval)}
              isDisabled={!isFormComplete}
              isLoading={buttonLoading}
            >
              Requisitar aprovação
            </GenericButton>
          )}
        </div>
        <div className="approved-project-body">
          <div className="body-basic-data">
            <LargeCardInfo borderRadius={10}>
              <CardHeader
                backgroundColor={'#004874'}
                borderRadius={10}
                fontSize={16}
                padding={'unset'}
                paddingLeft={'12px'}
                color={'#fff'}
                fontFamily={'Poppins'}
                fontStyle={'normal'}
                fontWeight={500}
                lineHeight={'36px'}
              >
                DADOS BÁSICOS DO PROJETO
              </CardHeader>

              <div className="large-card-info-body">
                <div className="large-card-column">
                  <div className="label-info-bundle">
                    <label>Nome do projeto</label>
                    <Controller
                      name="nome"
                      control={control}
                      render={({ field }) => <Input variant="flushed" {...field} isDisabled={!isEditMode} />}
                    />
                  </div>
                  <div className="label-info-bundle">
                    <label>Objetivo</label>
                    <Controller
                      name="objetivo"
                      control={control}
                      render={({ field }) => <Input variant="flushed" {...field} isDisabled={!isEditMode} />}
                    />
                  </div>
                  <div className="label-info-bundle">
                    <label>Subsecretaria Responsável</label>
                    <Controller
                      name="subsecretaria"
                      control={control}
                      render={({ field }) => (
                        <GenericSelect
                          isMulti
                          options={subsecretarias?.map((subsecretaria) => ({
                            label: subsecretaria.nome,
                            value: subsecretaria.id
                          }))}
                          {...field}
                          isDisabled={!isEditMode}
                        />
                      )}
                    />
                  </div>
                  <div className="label-info-bundle">
                    <label>Orçamento Estimado</label>
                    <Controller
                      name="orcamento"
                      control={control}
                      render={({ field }) => (
                        <NumberMaskInput
                          variant="flushed"
                          format="currency"
                          currency="BRL"
                          isDisabled={!isEditMode}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="label-info-bundle">
                    <label>Sedeics</label>
                    <Controller
                      name="sedeics"
                      control={control}
                      render={({ field }) => (
                        <GenericSelect
                          isMulti
                          options={sedeics?.map((subsecretaria) => ({
                            label: subsecretaria.nome,
                            value: subsecretaria.id
                          }))}
                          {...field}
                          isDisabled={!isEditMode}
                        />
                      )}
                    />
                  </div>
                  <div className="label-info-bundle">
                    <label>ODS</label>
                    <Controller
                      name="ods"
                      control={control}
                      render={({ field }) => (
                        <GenericSelect
                          isMulti
                          options={ods?.map((ods) => ({
                            label: generateOdsLabel(ods.nome, ods.id),
                            value: ods.id
                          }))}
                          {...field}
                          isDisabled={!isEditMode}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="large-card-column">
                  <div className="label-info-bundle">
                    <label>Justificativa</label>
                    <Controller
                      name="justificativa"
                      control={control}
                      render={({ field }) => <Input variant="flushed" {...field} isDisabled={!isEditMode} />}
                    />
                  </div>
                  <div className="label-info-bundle">
                    <label>Público - Alvo</label>
                    <Controller
                      name="publico_alvo"
                      control={control}
                      render={({ field }) => (
                        <GenericSelect
                          isMulti
                          options={publicoAlvoList?.map((publico_alvo) => ({
                            label: publico_alvo.nome,
                            value: publico_alvo.id
                          }))}
                          {...field}
                          isDisabled={!isEditMode}
                        />
                      )}
                    />
                  </div>
                  <div className="label-info-bundle">
                    <label>Duração do projeto</label>
                    <DuracaoPicker
                      selectedDates={selectedDates}
                      onDateChange={handleDateChange}
                      disabled={!isEditMode}
                    />
                  </div>
                  <div className="label-info-bundle">
                    <Controller
                      name="ppa"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          borderColor={'#979797'}
                          {...field}
                          value={field.value as unknown as string}
                          isChecked={currentFormObject?.ppa}
                          isDisabled={!isEditMode}
                        >
                          Orçamento previsto no PPA?
                        </Checkbox>
                      )}
                    />
                  </div>
                  <div className="label-info-bundle">
                    <label>Parcerias</label>
                    <Controller
                      name="parcerias"
                      control={control}
                      render={({ field }) => (
                        <ReactSelectCreatable
                          isMulti
                          noOptionsMessage={({ inputValue }) =>
                            !inputValue ? 'Digite para criar parceria.' : 'Nenhuma opção encontrada.'
                          }
                          isDisabled={!isEditMode}
                          placeholder={'Digitar...'}
                          {...field}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </LargeCardInfo>
            <SmallFormCard>
              <CardHeader
                backgroundColor={'#004874'}
                borderRadius={10}
                fontSize={16}
                padding={'unset'}
                paddingLeft={'12px'}
                color={'#fff'}
                fontFamily={'Poppins'}
                fontStyle={'normal'}
                fontWeight={500}
                lineHeight={'36px'}
              >
                EQUIPE
              </CardHeader>
              <div className="small-card-form">
                <div className="label-info-bundle">
                  <label>Responsável</label>
                  <Controller
                    name="responsavel"
                    control={control}
                    render={({ field }) => (
                      <GenericSelect
                        options={users?.map((user) => ({ label: user.nome, value: user.id }))}
                        {...field}
                        isDisabled={!isEditMode}
                      />
                    )}
                  />
                </div>
                <div className="label-info-bundle">
                  <label>Superintendência</label>
                  <Controller
                    name="gerente"
                    control={control}
                    render={({ field }) => (
                      <GenericSelect
                        options={users?.map((user) => ({ label: user.nome, value: user.id }))}
                        {...field}
                        isDisabled={!isEditMode}
                      />
                    )}
                  />
                </div>
                <div className="label-info-bundle">
                  <label>Equipe</label>
                  <Controller
                    name="equipe"
                    control={control}
                    render={({ field }) => (
                      <GenericSelect
                        isMulti
                        options={users?.map((user) => ({ label: user.nome, value: user.id }))}
                        {...field}
                        isDisabled={!isEditMode}
                      />
                    )}
                  />
                </div>
              </div>
            </SmallFormCard>
          </div>
          <div className="body-indicadores-metas">
            <IndicadoresList
              indicadores={indicadores}
              tempIndicadores={tempIndicadores}
              setShowNovoIndicadorModal={setShowNovoIndicadorModal}
              setIndicadorModalData={setIndicadorModalData}
              indicadoresLoading={indicadoresLoading}
              isPending={isPending}
            />
            <TarefaList
              tempTarefas={tempTarefas}
              tarefas={tarefas}
              setShowNovaTarefaModal={setShowNovaTarefaModal}
              setTarefaModalData={setTarefaModalData}
              tarefasLoading={tarefasLoading}
              isPending={isPending}
            />
            <GenericTable<IDocumentos>
              title="DOCUMENTOS"
              columns={documentoColumns}
              data={project?.documentos}
              isLoading={false}
              onAddClick={handleShowNovoDocumentoModal}
              onRowClick={(documento) => {
                setDocumentoModalData(documento)
                handleShowNovoDocumentoModal()
              }}
              noDataType="documentos"
            />
          </div>
          {!!params.id && <CommentChat comments={project?.comentarios} type="PROJETO" entityId={project?.id} />}
        </div>
      </FullyApprovedProjecteContainer>
      <ModalNovaTarefa
        projetoId={project?.id}
        setShowNovaTarefaModal={setShowNovaTarefaModal}
        setTarefaModalData={setTarefaModalData}
        showNovaTarefaModal={showNovaTarefaModal}
        tarefaModalData={tarefaModalData}
        refreshTarefas={refreshTarefas}
        setTempTarefas={setTempTarefas}
        tempTarefas={tempTarefas}
        isNewProjectScreen={!project}
      />
      <ModalNovoIndicador
        projetoId={project?.id}
        setIndicadorModalData={setIndicadorModalData}
        setShowNovoIndicadorModal={setShowNovoIndicadorModal}
        showNovoIndicadorModal={showNovoIndicadorModal}
        isNewProjectScreen={!project}
        indicadorModalData={indicadorModalData}
        refreshIndicadores={refreshIndicadores}
        setTempIndicadores={setTempIndicadores}
        tempIndicadores={tempIndicadores}
      />
    </>
  )
}
