import { FORGOT_PASSWORD, LOGIN, SIGN_UP, UPLOAD_PIC, USER, USERS, api } from 'sigap/utils/constants'

export interface IAuthUser {
  email: string
  password: string
}

export interface IAuthNewUser {
  email: string
  password: string
  subsecretaria: string
  cargo: string
}

export interface IForgotPassword {
  email: string
}

export async function listUsers(): Promise<IUser[]> {
  const { data: response } = await api.get(`/${USERS}`)

  return response
}

export async function me(): Promise<IUser> {
  const { data: response } = await api.get(`/${USERS}/me`)

  return response
}

export async function login(user: IAuthUser): Promise<{ access_token: string; user_id: number }> {
  const { data: response } = await api.post(`/${USER}/${LOGIN}`, user)

  return response
}

export async function signUp(user: IAuthNewUser): Promise<{ access_token: string }> {
  const { data: response } = await api.post(`/${USER}/${SIGN_UP}`, user)

  return response
}

export async function forgotPassword(forgotPasswordEmail: IForgotPassword): Promise<{ access_token: string }> {
  const { data: response } = await api.post(`/${USER}/${FORGOT_PASSWORD}`, forgotPasswordEmail)

  return response
}

export async function uploadPic(userId: number, file: File): Promise<{ access_token: string }> {
  const data = new FormData()
  data.append('file', file, file.name)
  const { data: response } = await api.post(`/${USER}/${UPLOAD_PIC}/${userId}`, data)

  return response
}
