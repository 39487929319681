import React from 'react'
import DataTable, { TableColumn } from 'react-data-table-component'
import { BsPlusLg } from 'react-icons/bs'

import { CircularProgress } from '@chakra-ui/react'

import { EndButtonRow } from 'sigap/utils/generic/EndButtonRow/styles'
import { GenericButton } from 'sigap/utils/generic/GenericButton/GenericButton'
import { NoDataComponent } from 'sigap/utils/generic/NoDataComponent/NoDataComponent'

import { GenericTableContainer } from './styles'

interface IGenericListProps<T> {
  title: string
  columns: TableColumn<T>[]
  data: T[]
  tempData?: T[]
  isPending?: boolean
  isLoading: boolean
  onAddClick: () => void
  onRowClick?: (row: T) => void
  noDataType?: string
}

export const GenericTable = <T,>({
  title,
  columns,
  data,
  tempData,
  isPending,
  isLoading,
  onAddClick,
  onRowClick,
  noDataType = 'dados'
}: IGenericListProps<T>): JSX.Element => {
  const handleOnRowClick = (row: T): void => {
    if (!isPending && onRowClick) {
      onRowClick(row)
    }
  }

  return (
    <GenericTableContainer>
      <div className="meta-header">{title}</div>
      <div className="meta-content">
        <div className="list-container">
          <div className="list-body">
            <DataTable
              columns={columns}
              data={tempData && tempData.length > 0 ? tempData : data}
              onRowClicked={handleOnRowClick}
              dense
              pointerOnHover={!isPending}
              highlightOnHover={!isPending}
              striped
              progressPending={isLoading}
              progressComponent={<CircularProgress isIndeterminate color="#001B37" />}
              noDataComponent={<NoDataComponent dataType={noDataType} />}
            />
          </div>
          <EndButtonRow style={{ padding: '16px' }}>
            <GenericButton
              className="add-button"
              colorScheme="green"
              onClick={onAddClick}
              isDisabled={isPending}
              rightIcon={<BsPlusLg />}
            >
              Adicionar
            </GenericButton>
          </EndButtonRow>
        </div>
      </div>
    </GenericTableContainer>
  )
}
