import React from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Button, FormControl, Input, useToast } from '@chakra-ui/react'

import { IForgotPassword } from 'sigap/services/users'
import { FORGOT_PASSWORD_TOAST_MESSAGE } from 'sigap/utils/constants/requestToastMessages'

interface IFormData {
  email: string
}

interface IProps {
  handleGoToDefaultAuth: () => void
  handleForgotPassword: (forgotPasswordEmail: IForgotPassword) => Promise<void>
}

export const ForgotPassword: React.FC<IProps> = ({ handleGoToDefaultAuth, handleForgotPassword }) => {
  const { handleSubmit, control } = useForm<IFormData>({
    mode: 'all'
  })

  const toast = useToast()

  const handleClickSubmit = (formObject: IFormData): void => {
    toast.promise<void, Error>(handleForgotPassword(formObject), FORGOT_PASSWORD_TOAST_MESSAGE)
  }

  return (
    <>
      <div className="auth-header">Esqueceu sua senha?</div>
      <FormControl className="form-control-container">
        <Controller
          name="email"
          control={control}
          render={({ field }) => <Input variant="flushed" placeholder="E-mail" type="email" {...field} />}
        />
      </FormControl>
      <Button width={'100%'} colorScheme="green" onClick={handleSubmit(handleClickSubmit)}>
        Recuperar senha
      </Button>
      <div className="bottom-link-container">
        <div className="go-back-login">
          Já possui uma conta?<span onClick={handleGoToDefaultAuth}>Login</span>
        </div>
      </div>
    </>
  )
}
