import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { AuthenticationContext } from 'sigap/context/AuthenticationContext'
import { sideBarSecretariaLogoNoAuth, sideBarSigestLogoNoAuth } from 'sigap/resources/images'
import { storageService } from 'sigap/services/storageService'
import { forgotPassword, IAuthNewUser, IAuthUser, IForgotPassword, login, signUp } from 'sigap/services/users'
import { api } from 'sigap/utils/constants'
import { ACCESS_TOKEN } from 'sigap/utils/constants/localStorage'

import { DefaultLogin } from './DefaultLogin/DefaultLogin'
import { ForgotPassword } from './ForgotPassword/ForgotPassword'
import { NewUser } from './NewUser/NewUser'
import { AuthenticationScreenContainer, NoAuthSideBarContainer } from './styles'

export const Authentication: React.FC = () => {
  const [authPage, setAuthPage] = useState<'DEFAULT' | 'NEW' | 'FORGOT'>('DEFAULT')

  const navigate = useNavigate()

  const authenticationContext = useContext(AuthenticationContext)

  const handleLogin = async (user: IAuthUser): Promise<void> => {
    try {
      const accessToken = await login(user)
      storageService.setItem(ACCESS_TOKEN, accessToken.access_token)

      api.updateAccessToken(accessToken.access_token)

      authenticationContext.setIsAuthenticated(true)
    } catch (e) {
      console.log(e)
    }
  }

  const handleForgotPassword = async (forgotPasswordEmail: IForgotPassword): Promise<void> => {
    try {
      await forgotPassword(forgotPasswordEmail)
    } catch (e) {
      console.log(e)
    }
  }

  const handleNewUser = async (newUser: IAuthNewUser): Promise<void> => {
    try {
      await signUp(newUser)
    } catch (error) {
      console.log(error)
    }
  }

  const handleGoToForgotPassword = (): void => {
    setAuthPage('FORGOT')
  }

  const handleGoToDefaultAuth = (): void => {
    setAuthPage('DEFAULT')
  }

  const handleGoToNewUser = (): void => {
    setAuthPage('NEW')
  }

  useEffect(() => {
    if (authenticationContext.isAuthenticated) {
      navigate('/dashboard')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationContext.isAuthenticated])

  return (
    <AuthenticationScreenContainer>
      <NoAuthSideBarContainer>
        <div className="side-bar-sigest-logo-container">
          <img src={sideBarSigestLogoNoAuth} alt="Logo Sigest barra lateral" />
        </div>
        <div className="side-bar-secretaria-logo-container">
          <img src={sideBarSecretariaLogoNoAuth} alt="Logo Secretaria barra lateral" />
        </div>
      </NoAuthSideBarContainer>
      <div className="auth-page">
        <div className="auth-main-container">
          {authPage === 'DEFAULT' ? (
            <DefaultLogin
              handleLogin={handleLogin}
              handleGoToForgotPassword={handleGoToForgotPassword}
              handleGoToNewUser={handleGoToNewUser}
            />
          ) : authPage === 'FORGOT' ? (
            <ForgotPassword handleGoToDefaultAuth={handleGoToDefaultAuth} handleForgotPassword={handleForgotPassword} />
          ) : (
            <NewUser handleGoToDefaultAuth={handleGoToDefaultAuth} handleNewUser={handleNewUser} />
          )}
        </div>
      </div>
    </AuthenticationScreenContainer>
  )
}
