import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import {
  Button,
  Input,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast
} from '@chakra-ui/react'

import { editProdutoOnContract, insertProdutoOnContract } from 'sigap/services/produtos'
import { IProduto } from 'sigap/types/contracts'
import { PRODUTO_CREATE_TOAST_MESSAGE, PRODUTO_EDIT_TOAST_MESSAGE } from 'sigap/utils/constants/requestToastMessages'
import { GenericSelect } from 'sigap/utils/generic/GenericSelect/GenericSelect'
import { NumberMaskInput } from 'sigap/utils/generic/MaskInput/MaskInput'
import { ModalContainer } from 'sigap/utils/generic/ModalContainer/ModalContainer'
import { SingleDatePicker } from 'sigap/utils/generic/SingleDatePicker/SingleDatePicker'
import { addThreeDigits } from 'sigap/utils/methods/addThreeDigits'
import { formatCurrencyToNumber } from 'sigap/utils/methods/formatCurrencyToNumber'

interface IFormData {
  nome: string
  responsavel: Option<number>
  valor: string
}

interface IProps {
  showProdutoModal: boolean
  setShowNovoProdutoModal: React.Dispatch<React.SetStateAction<boolean>>
  users: IUser[]
  contratoId: number
  setProdutoModalData: React.Dispatch<React.SetStateAction<IProduto>>
  produtoModalData?: IProduto
  setTempProdutos: React.Dispatch<React.SetStateAction<IProduto[]>>
  tempProdutos?: IProduto[]
  refreshProdutos: () => Promise<IProduto[]>
  isNewContratoScreen?: boolean
}

export const ModalNovoProduto: React.FC<IProps> = ({
  showProdutoModal,
  setShowNovoProdutoModal,
  users,
  contratoId,
  refreshProdutos,
  setProdutoModalData,
  setTempProdutos,
  isNewContratoScreen,
  produtoModalData,
  tempProdutos
}) => {
  const { handleSubmit, control, setValue, watch } = useForm<IFormData>()

  const [entregaSelectedDate, setEntregaSelectedDate] = useState<Date>(new Date())
  const [pagamentoSelectedDate, setPagamentoSelectedDate] = useState<Date>(new Date())
  const [count, setCount] = useState<number>(1)

  const currentFormData = watch()
  const toast = useToast()

  const isFormComplete = useMemo(
    () => currentFormData.nome?.length > 0 && currentFormData.responsavel != null && currentFormData.valor?.length > 0,
    [currentFormData]
  )

  const handleRangeDateChange = (date: Date): void => {
    setPagamentoSelectedDate(date)
  }

  const handleSingleDateChange = (date: Date): void => {
    setEntregaSelectedDate(date)
  }

  const handleCleanForm = (): void => {
    setEntregaSelectedDate(new Date())
    setPagamentoSelectedDate(new Date())
    setValue('nome', '')
    setValue('responsavel', undefined)
    setValue('valor', undefined)
  }

  const handleCloseNovoProdutoModal = (): void => {
    handleCleanForm()
    setShowNovoProdutoModal(false)
    setProdutoModalData(undefined)
  }

  const normalizeProdutoObject = (formObject: IFormData): IProduto => {
    if (entregaSelectedDate && pagamentoSelectedDate) {
      const normalizedProdutoObject: IProduto = {
        data_pagamento: pagamentoSelectedDate.getTime(),
        prazo_entrega: pagamentoSelectedDate.getTime(),
        nome: formObject?.nome,
        valor: formatCurrencyToNumber(formObject?.valor),
        responsavel: formObject?.responsavel.value
      }

      return normalizedProdutoObject
    }
  }

  const handleCreateProduto = async (formObject: IFormData): Promise<void> => {
    const normalizedProduto = normalizeProdutoObject(formObject)
    await insertProdutoOnContract({ produtos: [normalizedProduto] }, contratoId)
    refreshProdutos()
    handleCloseNovoProdutoModal()
  }

  const returnBasicInfoFromOption = (option: Option<number>): IBasicInfo => {
    const basicInfo: IBasicInfo = { id: option.value, nome: option.label as string }

    return basicInfo
  }

  const normalizeTempProdutoObject = (formObject: IFormData): IProduto => {
    if (entregaSelectedDate && pagamentoSelectedDate) {
      const normalizedTempProdutoObject: IProduto = {
        data_pagamento: pagamentoSelectedDate.getTime(),
        prazo_entrega: pagamentoSelectedDate.getTime(),
        nome: formObject?.nome,
        valor: formatCurrencyToNumber(formObject?.valor),
        responsavel: returnBasicInfoFromOption(formObject?.responsavel),
        id: count
      }
      setCount(count + 1)

      return normalizedTempProdutoObject
    }
  }

  const handleNovoTempProduto = (formObject: IFormData): void => {
    const normalizedTempProduto = normalizeTempProdutoObject(formObject)
    setTempProdutos((current) => [...current, normalizedTempProduto])
    handleCleanForm()
    handleCloseNovoProdutoModal()
  }

  const handleEditTempProduto = (formObject: IFormData): void => {
    if (produtoModalData) {
      const normalizedTempTarefa = normalizeTempProdutoObject(formObject)
      const newData = tempProdutos.map((indicador) => {
        if (indicador.id === produtoModalData.id) {
          return normalizedTempTarefa
        }

        return indicador
      })
      setTempProdutos(newData)
      handleCloseNovoProdutoModal()
    }
  }

  const handleClickSubmitCreate = (formObject: IFormData): void => {
    toast.promise<void, Error>(handleCreateProduto(formObject), PRODUTO_CREATE_TOAST_MESSAGE)
  }

  const handleEditProduto = async (formObject: IFormData): Promise<void> => {
    const normalizedProduto = normalizeProdutoObject(formObject)
    await editProdutoOnContract({ produtos: [normalizedProduto] }, contratoId)
    refreshProdutos()
    handleCloseNovoProdutoModal()
  }

  const handleClickSubmitEdit = (formObject: IFormData): void => {
    toast.promise<void, Error>(handleEditProduto(formObject), PRODUTO_EDIT_TOAST_MESSAGE)
  }

  useEffect(() => {
    if (produtoModalData) {
      setValue('nome', produtoModalData.nome)
      setValue('responsavel', {
        label: (produtoModalData.responsavel as IBasicInfo)?.nome,
        value: (produtoModalData.responsavel as IBasicInfo)?.id
      })
      setValue('valor', new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(produtoModalData.valor))
      setPagamentoSelectedDate(new Date(addThreeDigits(produtoModalData.data_pagamento)))
      setEntregaSelectedDate(new Date(addThreeDigits(produtoModalData.prazo_entrega)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [produtoModalData])

  return (
    <ModalContainer isOpen={showProdutoModal} onClose={console.log}>
      <ModalOverlay />
      <ModalContent minWidth={800} className="nova-meta-modal-content">
        <ModalHeader
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          className="nova-meta-modal-header"
          color={'gray.900'}
          fontFamily={'Poppins'}
          fontSize={32}
          fontStyle={'normal'}
          fontWeight={700}
          lineHeight={'36px'}
        >
          Novo Produto
        </ModalHeader>
        <ModalBody
          padding={'0px 48px'}
          gap={'32px'}
          className="nova-meta-modal-body"
          display={'flex'}
          flexDirection={'column'}
        >
          <div className="label-input-bundle" style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <label>Nome do Produto</label>
            <Controller name="nome" control={control} render={({ field }) => <Input variant="flushed" {...field} />} />
          </div>
          <div className="label-input-bundle" style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <label>Responsável pelo Produto</label>
            <Controller
              name="responsavel"
              control={control}
              render={({ field }) => (
                <GenericSelect options={users?.map((user) => ({ label: user.nome, value: user.id }))} {...field} />
              )}
            />
          </div>

          <div className="label-input-bundle" style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <label>Data de Entrega</label>
            <SingleDatePicker onDateChange={handleRangeDateChange} selectedDate={pagamentoSelectedDate} />
          </div>
          <div className="label-input-bundle" style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <label>Valor do Produto</label>

            <Controller
              name="valor"
              control={control}
              render={({ field }) => <NumberMaskInput variant="flushed" format="currency" currency="BRL" {...field} />}
            />
          </div>
          <div className="label-input-bundle" style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <label>Data de Pagamento</label>
            <SingleDatePicker onDateChange={handleSingleDateChange} selectedDate={entregaSelectedDate} />
          </div>
        </ModalBody>
        <ModalFooter display={'flex'} gap={'80px'}>
          {!isNewContratoScreen ? (
            <>
              {produtoModalData ? (
                <Button onClick={handleSubmit(handleClickSubmitEdit)} isDisabled={!isFormComplete}>
                  Salvar Edição
                </Button>
              ) : (
                <Button onClick={handleSubmit(handleClickSubmitCreate)} isDisabled={!isFormComplete}>
                  Adicionar e Salvar
                </Button>
              )}
            </>
          ) : produtoModalData ? (
            <Button onClick={handleSubmit(handleEditTempProduto)} isDisabled={!isFormComplete}>
              Salvar Edição
            </Button>
          ) : (
            <Button onClick={handleSubmit(handleNovoTempProduto)} isDisabled={!isFormComplete}>
              Adicionar
            </Button>
          )}

          <Button variant={'outline'} onClick={handleCloseNovoProdutoModal} color={'#242731'}>
            Cancelar
          </Button>
        </ModalFooter>
      </ModalContent>
    </ModalContainer>
  )
}
