import styled from 'styled-components'

export const IndicadoresTarefasContainer = styled.div`
  .meta-header {
    color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    background: #005a92;
    border-radius: 10px;
    padding: 0 25px;
    display: inline-block;
    height: 100%;
    position: relative;
    z-index: 1;
    margin-left: 35px;
  }

  .meta-content {
    border-radius: 10px;
    border: 1px solid rgba(151, 151, 151, 0.38);
    background: rgba(217, 217, 217, 0.2);
    transform: translateY(-14px);
    padding-top: 24px;
    .meta-name {
      padding: 8px 48px;
    }

    .tarefa-list {
      padding: 0 32px;
      .tarefa-list-header {
        border-radius: 10px 10px 0 0;
        background: #005a92;
        color: #fff;
        padding-left: 20px;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
      }

      .tarefa-list-body {
        border: 1px solid rgba(151, 151, 151, 0.38);
        background: #fff;
      }

      .tarefa-list-btn-container {
        display: flex;
        justify-content: flex-end;
        padding: 16px;
        .nova-tarefa-button {
          display: flex;
          gap: 8px;
        }
      }
    }
  }
`
