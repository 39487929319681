import { createGlobalStyle } from 'styled-components'

export const GlobalTheme = createGlobalStyle`
:root {
    ${({ theme }) =>
      Object.entries(theme)
        .filter(([_key, value]) => typeof value === 'string')
        .map(([key, value]) => `--${key}: ${value}`)
        .join(';\n') + ';\n'}
  }


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  
}


#root {
  display: flex;
  
}

`
