import React, { useContext, useEffect } from 'react'
import { MdOutlineNotifications, MdOutlineVerifiedUser } from 'react-icons/md'
import { RiUserLine } from 'react-icons/ri'
import { Routes, Route, useNavigate } from 'react-router-dom'

import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger
} from '@chakra-ui/react'

import { AuthenticationContext } from 'sigap/context/AuthenticationContext'
import { storageService } from 'sigap/services/storageService'
import { me } from 'sigap/services/users'
import { Perfis } from 'sigap/utils/constants/enums'
import { ACCESS_TOKEN } from 'sigap/utils/constants/localStorage'
import { CustomRoute } from 'sigap/utils/generic/CustomRoutes/CustomRoutes'

import { Admin } from './Admin/Admin'
import { Authentication } from './Authentication/Authentication'
import { ContractEditor } from './Contracts/ContractEditor/ContractEditor'
import { Contracts } from './Contracts/Contracts'
import { NewContract } from './Contracts/NewContract/NewContract'
import { Dashboard } from './Dashboard/Dashboard'
import { DemandEditor } from './Demands/DemandEditor/DemandEditor'
import { Demands } from './Demands/Demands'
import { NewDemand } from './Demands/NewDemand/NewDemand'
import { KanbanBoard } from './KanbanBoard'
import { KanbanCalendar } from './KanbanBoard/KanbanCalendar/KanbanCalendar'
import { Perfil } from './Perfil/Perfil'
import { ProjectEditor } from './Projects/ProjectEditor/ProjectEditor'
import { Projects } from './Projects/Projects'
import { ViewContainer } from './styles'
import { Team } from './Team/Team'

export const View: React.FC = () => {
  const authContext = useContext(AuthenticationContext)

  const navigate = useNavigate()

  const handleDefineUser = async (): Promise<void> => {
    try {
      const loggedUser = await me()
      console.log(loggedUser)
      authContext.setCurrentUser(loggedUser)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (authContext.isAuthenticated && !authContext.currentUser) {
      handleDefineUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext.currentUser, authContext.isAuthenticated])

  if (!authContext.isAuthenticated) {
    return (
      <ViewContainer style={{ width: '100%', overflow: 'hidden' }}>
        <Routes>
          <Route path="/login" element={<CustomRoute Component={Authentication} />} />
          <Route path="/*" element={<CustomRoute Component={Authentication} />} />
        </Routes>
      </ViewContainer>
    )
  }

  return (
    <ViewContainer>
      <div className="view-top-bar">
        <Button backgroundColor={'gray.200'} _hover={{ bg: 'white' }} isDisabled>
          <MdOutlineNotifications />
        </Button>

        <Popover>
          <PopoverTrigger>
            <Button backgroundColor={'gray.200'} _hover={{ bg: 'white' }}>
              <RiUserLine />
            </Button>
          </PopoverTrigger>
          <PopoverContent position={'relative'} bottom={0}>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Sair</PopoverHeader>
            <PopoverBody>
              <Button
                onClick={() => {
                  navigate('/perfil')
                }}
              >
                Ver perfil
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  storageService.removeItem(ACCESS_TOKEN)
                  navigate('/')
                  window.location.reload()
                }}
              >
                Sair
              </Button>
            </PopoverBody>
          </PopoverContent>
        </Popover>
        {authContext.currentUser?.perfil === Perfis.ADMIN && (
          <Button
            onClick={() => {
              navigate('/admin')
            }}
            backgroundColor={'gray.200'}
            _hover={{ bg: 'white' }}
          >
            <MdOutlineVerifiedUser />
          </Button>
        )}
      </div>
      <Routes>
        <Route path="/dashboard" element={<CustomRoute Component={Dashboard} />} />
        <Route path="/projetos" element={<CustomRoute Component={Projects} />} />
        <Route path="/projetos/novo" element={<CustomRoute Component={ProjectEditor} />} />
        <Route path="/projetos/:id" element={<CustomRoute Component={ProjectEditor} />} />
        <Route path="/contratos" element={<CustomRoute Component={Contracts} />} />
        <Route path="/contratos/novo" element={<CustomRoute Component={ContractEditor} />} />
        <Route path="/contratos/:id" element={<CustomRoute Component={ContractEditor} />} />
        <Route path="/demandas" element={<CustomRoute Component={Demands} />} />
        <Route path="/demandas/novo" element={<CustomRoute Component={DemandEditor} />} />
        <Route path="/demandas/:id" element={<CustomRoute Component={DemandEditor} />} />
        <Route path="/tarefas" element={<CustomRoute Component={KanbanBoard} />} />
        <Route path="/tarefas/calendario" element={<CustomRoute Component={KanbanCalendar} />} />
        <Route path="/equipe" element={<CustomRoute Component={Team} />} />
        <Route path="/admin" element={<CustomRoute Component={Admin} />} />
        <Route path="/perfil" element={<CustomRoute Component={Perfil} />} />
        <Route path="*" element={<CustomRoute Component={Dashboard} />} />
      </Routes>
    </ViewContainer>
  )
}
