import React, { useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Button, FormControl, Input, InputGroup, InputRightElement, useToast } from '@chakra-ui/react'

import { IAuthUser } from 'sigap/services/users'
import { LOGIN_TOAST_MESSAGE } from 'sigap/utils/constants/requestToastMessages'

interface IFormData {
  email: string
  password: string
}

interface IProps {
  handleLogin: (user: IAuthUser) => Promise<void>
  handleGoToForgotPassword: () => void
  handleGoToNewUser: () => void
}

export const DefaultLogin: React.FC<IProps> = ({ handleLogin, handleGoToForgotPassword, handleGoToNewUser }) => {
  const [show, setShow] = useState<boolean>(false)

  const { handleSubmit, control, watch } = useForm<IFormData>({
    mode: 'all'
  })

  const toast = useToast()
  const currentFormData = watch()

  const isFormComplete = useMemo(
    () => currentFormData.email?.length > 0 && currentFormData.password?.length > 0,
    [currentFormData]
  )

  const handleShowPassword = (): void => setShow(!show)

  const handleClickSubmit = (formObject: IFormData): void => {
    toast.promise<void, Error>(handleLogin(formObject), LOGIN_TOAST_MESSAGE)
  }

  return (
    <>
      <div className="auth-header">Login</div>
      <FormControl className="form-control-container">
        <Controller
          name="email"
          control={control}
          render={({ field }) => <Input variant="flushed" placeholder="E-mail" type="email" {...field} />}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <InputGroup size="md" {...field}>
              <Input
                variant="flushed"
                type={show ? 'text' : 'password'}
                placeholder="Senha"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit(handleClickSubmit)()
                  }
                }}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleShowPassword}>
                  {show ? 'Hide' : 'Show'}
                </Button>
              </InputRightElement>
            </InputGroup>
          )}
        />
      </FormControl>
      <Button width={'100%'} colorScheme="green" onClick={handleSubmit(handleClickSubmit)} isDisabled={!isFormComplete}>
        Acessar
      </Button>
      <div className="bottom-link-container">
        <div className="forgot-password" onClick={handleGoToForgotPassword}>
          Esqueceu sua senha?
        </div>
        <div className="new-user" onClick={handleGoToNewUser}>
          Novo usuário
        </div>
      </div>
    </>
  )
}
