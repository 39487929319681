import React, { useState, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'

import {
  Button,
  Input,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToast
} from '@chakra-ui/react'
import { useRequest } from 'ahooks'

import { editTarefasOnProject, insertTarefasOnProject } from 'sigap/services/tarefas'
import { listUsers } from 'sigap/services/users'
import { ITarefa } from 'sigap/types/tarefas'
import { TAREFA_CREATE_TOAST_MESSAGE, TAREFA_EDIT_TOAST_MESSAGE } from 'sigap/utils/constants/requestToastMessages'
import { GenericButton } from 'sigap/utils/generic/GenericButton/GenericButton'
import { GenericSelect } from 'sigap/utils/generic/GenericSelect/GenericSelect'
import { ModalContainer } from 'sigap/utils/generic/ModalContainer/ModalContainer'
import { SingleDatePicker } from 'sigap/utils/generic/SingleDatePicker/SingleDatePicker'
import { addThreeDigits } from 'sigap/utils/methods/addThreeDigits'
import { returnBasicInfoArrayFromOptionArray } from 'sigap/utils/methods/returnBasicInfoArrayFromOptionArray'
import { returnNumberValueArrayFromMultiSelect } from 'sigap/utils/methods/returnNumberValueArrayFromMultiSelect'

interface IFormData {
  nome: string
  descricao: string
  responsavel: Option<number>[]
}

interface IProps {
  showNovaTarefaModal: boolean
  setShowNovaTarefaModal: (k: boolean) => void
  projetoId: number
  setTarefaModalData: React.Dispatch<React.SetStateAction<ITarefa>>
  tarefaModalData?: ITarefa
  refreshTarefas: () => Promise<ITarefa[]>
  setTempTarefas?: React.Dispatch<React.SetStateAction<ITarefa[]>>
  tempTarefas?: ITarefa[]
  isNewProjectScreen?: boolean
}

export const ModalNovaTarefa: React.FC<IProps> = ({
  showNovaTarefaModal,
  setShowNovaTarefaModal,
  projetoId,
  tarefaModalData,
  setTarefaModalData,
  refreshTarefas,
  tempTarefas,
  setTempTarefas,
  isNewProjectScreen
}) => {
  const { handleSubmit, control, setValue, watch } = useForm<IFormData>()
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [count, setCount] = useState<number>(1)

  const currentFormData = watch()

  const toast = useToast()

  const { data: users } = useRequest(listUsers)

  const isFormComplete = useMemo(
    () => currentFormData.nome?.length > 0 && currentFormData.responsavel != null,
    [currentFormData]
  )

  const handleCleanForm = (): void => {
    setSelectedDate(new Date())
    setValue('nome', '')
    setValue('responsavel', undefined)
  }

  const handleCloseNovaTarefaModal = (): void => {
    handleCleanForm()
    setShowNovaTarefaModal(false)
    setTarefaModalData(undefined)
  }
  const handleDateChange = (date: Date): void => {
    setSelectedDate(date)
  }

  const normalizeTarefaObject = (formObject: IFormData): ITarefa => {
    if (selectedDate) {
      const normalizedTarefaObject: ITarefa = {
        data_acao: selectedDate.getTime(),
        titulo: formObject.nome,
        descricao: formObject?.descricao,
        responsavel: returnNumberValueArrayFromMultiSelect(formObject.responsavel),

        id_tarefa: tarefaModalData?.id_tarefa
      }

      return normalizedTarefaObject
    }
  }

  const handleCreateTarefa = async (formObject: IFormData): Promise<void> => {
    const normalizedTarefa = normalizeTarefaObject(formObject)
    await insertTarefasOnProject({ tarefas: [normalizedTarefa] }, projetoId)
    refreshTarefas()
    handleCloseNovaTarefaModal()
  }

  const normalizeTempTarefaObject = (formObject: IFormData): ITarefa => {
    if (selectedDate) {
      const normalizedTempTarefaObject: ITarefa = {
        data_acao: selectedDate.getTime(),
        titulo: formObject.nome,
        descricao: formObject?.descricao,
        responsavel: returnBasicInfoArrayFromOptionArray(formObject.responsavel),
        id: count,
        id_tarefa: tarefaModalData?.id_tarefa
      }
      setCount(count + 1)

      return normalizedTempTarefaObject
    }
  }

  const handleNovaTempTarefa = (formObject: IFormData): void => {
    const normalizedTempTarefa = normalizeTempTarefaObject(formObject)
    setTempTarefas((current) => [...current, normalizedTempTarefa])
    handleCleanForm()
    setShowNovaTarefaModal(false)
  }

  const handleEditTempTarefa = (formObject: IFormData): void => {
    if (tarefaModalData) {
      const normalizedTempTarefa = normalizeTempTarefaObject(formObject)
      const newData = tempTarefas.map((indicador) => {
        if (indicador.id === tarefaModalData.id) {
          return normalizedTempTarefa
        }

        return indicador
      })
      setTempTarefas(newData)
      handleCloseNovaTarefaModal()
    }
  }

  const handleClickSubmitCreate = (formObject: IFormData): void => {
    toast.promise<void, Error>(handleCreateTarefa(formObject), TAREFA_CREATE_TOAST_MESSAGE)
  }

  const handleEditTarefa = async (formObject: IFormData): Promise<void> => {
    const normalizedTarefa = normalizeTarefaObject(formObject)
    await editTarefasOnProject({ tarefas: [normalizedTarefa] }, projetoId)
    refreshTarefas()
    handleCloseNovaTarefaModal()
  }

  const handleClickSubmitEdit = (formObject: IFormData): void => {
    toast.promise<void, Error>(handleEditTarefa(formObject), TAREFA_EDIT_TOAST_MESSAGE)
  }

  const returnOptionArrayFromUserArray = (users: IUser[]): Option<number>[] => {
    const optionsArray: Option<number>[] = []

    users?.map((user) => optionsArray.push({ label: user.nome, value: user.id }))

    return optionsArray
  }

  useEffect(() => {
    if (tarefaModalData) {
      setValue('nome', tarefaModalData.titulo)
      setValue('descricao', tarefaModalData.descricao)
      setValue('responsavel', returnOptionArrayFromUserArray(tarefaModalData.responsavel as IUser[]))
      setSelectedDate(new Date(addThreeDigits(tarefaModalData.data_acao)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tarefaModalData])

  return (
    <ModalContainer isOpen={showNovaTarefaModal} onClose={console.log}>
      <ModalOverlay />
      <ModalContent minWidth={800} className="nova-meta-modal-content">
        <ModalHeader
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          className="nova-meta-modal-header"
          color={'gray.900'}
          fontFamily={'Poppins'}
          fontSize={32}
          fontStyle={'normal'}
          fontWeight={700}
          lineHeight={'36px'}
        >
          Nova Tarefa
        </ModalHeader>
        <ModalBody
          display={'flex'}
          flexDirection={'column'}
          padding={'0px 48px'}
          gap={'32px'}
          className="nova-meta-modal-body"
        >
          <div className="label-input-bundle">
            <label>Nome da Tarefa</label>
            <Controller name="nome" control={control} render={({ field }) => <Input variant="flushed" {...field} />} />
          </div>
          <div className="new-project-label-input-bundle">
            <label>Descrição</label>
            <Controller name="descricao" control={control} render={({ field }) => <Textarea {...field} />} />
          </div>
          <div className="label-input-bundle">
            <label>Responsável pela Tarefa</label>
            <Controller
              name="responsavel"
              control={control}
              render={({ field }) => (
                <GenericSelect
                  isMulti
                  required
                  options={users?.map((user) => ({ label: user.nome, value: user.id }))}
                  {...field}
                />
              )}
            />
          </div>
          <div className="label-input-bundle">
            <label>Prazo de Realização</label>
            <SingleDatePicker onDateChange={handleDateChange} selectedDate={selectedDate} />
          </div>
        </ModalBody>
        <ModalFooter display={'flex'} gap={'80px'}>
          {!isNewProjectScreen ? (
            <>
              {tarefaModalData ? (
                <GenericButton onClick={handleSubmit(handleClickSubmitEdit)} isDisabled={!isFormComplete}>
                  Salvar Edição
                </GenericButton>
              ) : (
                <GenericButton onClick={handleSubmit(handleClickSubmitCreate)} isDisabled={!isFormComplete}>
                  Adicionar e Salvar
                </GenericButton>
              )}
            </>
          ) : tarefaModalData ? (
            <GenericButton onClick={handleSubmit(handleEditTempTarefa)} isDisabled={!isFormComplete}>
              Salvar Edição
            </GenericButton>
          ) : (
            <GenericButton onClick={handleSubmit(handleNovaTempTarefa)} isDisabled={!isFormComplete}>
              Adicionar
            </GenericButton>
          )}

          <GenericButton variant={'outline'} color={'#242731'} onClick={handleCloseNovaTarefaModal}>
            Cancelar
          </GenericButton>
        </ModalFooter>
      </ModalContent>
    </ModalContainer>
  )
}
