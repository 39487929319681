import React from 'react'

import { SingleDatepicker } from 'chakra-dayzed-datepicker'

interface IProps {
  selectedDate: Date
  onDateChange: (dates: Date) => void
}

export const SingleDatePicker: React.FC<IProps> = ({ selectedDate, onDateChange }) => {
  return (
    <SingleDatepicker
      date={selectedDate}
      onDateChange={onDateChange}
      configs={{ dateFormat: 'dd/MM/yy' }}
      propsConfigs={{
        dayOfMonthBtnProps: {
          defaultBtnProps: {
            _hover: {
              backgroundColor: '#91d8f7'
            }
          },
          isInRangeBtnProps: { backgroundColor: '#24aae1' },
          todayBtnProps: { backgroundColor: '#91d8f7' },
          selectedBtnProps: { backgroundColor: '#24aae1' }
        }
      }}
    />
  )
}
