import React from 'react'
import DataTable, { TableColumn } from 'react-data-table-component'
import { BsPlusLg } from 'react-icons/bs'

import { CircularProgress } from '@chakra-ui/react'
import * as dateFns from 'date-fns'

import { ITarefa } from 'sigap/types/tarefas'
import { DateFormat } from 'sigap/utils/constants/enums'
import { EndButtonRow } from 'sigap/utils/generic/EndButtonRow/styles'
import { GenericButton } from 'sigap/utils/generic/GenericButton/GenericButton'
import { NoDataComponent } from 'sigap/utils/generic/NoDataComponent/NoDataComponent'
import { addThreeDigits } from 'sigap/utils/methods/addThreeDigits'
import { returnStringFromBasicInfoArray } from 'sigap/utils/methods/returnStringFromBasicInfoArray'

import { IndicadoresTarefasContainer } from '../IndicadoresList/styles'

const columns: TableColumn<ITarefa>[] = [
  {
    name: 'Descrição',
    selector: (row) => row.titulo,
    sortable: true
  },
  {
    name: 'Responsável',
    selector: (row) =>
      row.responsavel && row.responsavel.length > 0
        ? returnStringFromBasicInfoArray(row.responsavel as IBasicInfo[])
        : '-',
    sortable: true
  },

  {
    name: 'Prazo',
    selector: (row) => dateFns.format(addThreeDigits(row.data_acao), DateFormat.BR_DATE),
    sortable: true
  }
]

interface IProps {
  setShowNovaTarefaModal: React.Dispatch<React.SetStateAction<boolean>>
  tempTarefas?: ITarefa[]
  tarefas: ITarefa[]
  setTarefaModalData: React.Dispatch<React.SetStateAction<ITarefa>>
  isPending?: boolean
  tarefasLoading: boolean
}

export const TarefaList: React.FC<IProps> = ({
  setShowNovaTarefaModal,
  tarefas,
  tempTarefas,
  setTarefaModalData,
  isPending,
  tarefasLoading
}) => {
  const handleShowNovaTarefaModal = (): void => {
    setShowNovaTarefaModal(true)
  }

  const handleOnRowClick = (tarefa: ITarefa): void => {
    handleShowNovaTarefaModal()
    setTarefaModalData(tarefa)
  }

  return (
    <IndicadoresTarefasContainer>
      <div className="meta-header">TAREFAS</div>
      <div className="meta-content">
        <div className="tarefa-list">
          <div className="tarefa-list-body">
            <DataTable
              columns={columns}
              data={tempTarefas.length > 0 ? tempTarefas : tarefas}
              onRowClicked={(tarefa) => (!isPending ? handleOnRowClick(tarefa) : undefined)}
              dense
              pointerOnHover={!isPending}
              highlightOnHover={!isPending}
              striped
              progressPending={tarefasLoading}
              progressComponent={<CircularProgress isIndeterminate color="#001B37" />}
              noDataComponent={<NoDataComponent dataType="tarefas" />}
            />
          </div>
          <EndButtonRow style={{ padding: '16px' }}>
            <GenericButton
              className="nova-tarefa-button"
              colorScheme="green"
              onClick={handleShowNovaTarefaModal}
              isDisabled={isPending}
              rightIcon={<BsPlusLg />}
            >
              Adicionar Tarefa
            </GenericButton>
          </EndButtonRow>
        </div>
      </div>
    </IndicadoresTarefasContainer>
  )
}
